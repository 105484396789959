<template>
  <label for="">
    <v-btn v-if="!open" color="accent" block class="ma-0" flat @click="handleClick">
      Subir Documento <v-icon right dark> cloud_upload </v-icon>
    </v-btn>
    <v-btn v-else class="ma-0" color="accent" block flat @click="handleRemoveClick">
      Quitar <v-icon right dark> close </v-icon>
    </v-btn>
    <span v-if="file" class="caption" v-html="`Subir ${file.name}`"></span>
    <input
      id="file"
      ref="file"
      type="file"
      style="display: none"
      accept=".doc, .docx, .pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
      @change="handleFileUploadSii()"
    />
  </label>
</template>

<script>
export default {
  name: 'FileReader',
  data() {
    return {
      open: false,
      file: null
    }
  },
  methods: {
    handleClick() {
      this.$refs.file.click()
    },
    handleFileUploadSii() {
      // eslint-disable-next-line prefer-destructuring
      this.$emit('input', this.$refs.file.files[0])
      this.file = this.$refs.file.files[0]
      this.open = true
    },
    handleRemoveClick() {
      this.$emit('input', '')
      this.file = null
      this.open = false
      this.$refs.file.value = ''
    }
  }
}
</script>
