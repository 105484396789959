<template>
  <v-layout v-if="_.size(detail) > 0" row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg6 md6>
      <v-card class="mb-3">
        <titleCard title="Cliente" subtitle="Datos de contacto del comprador"> </titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <v-layout row wrap>
            <v-flex v-if="detail.shipping.type === 'ANONIMO'" xs12>
              <v-alert v-model="detail.shipping.type" type="error" icon="new_releases" class="ml-0 mb-3">
                <span v-html="`ENTREGA ES ANONIMA`"></span>
              </v-alert>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="detail.user.name"
                label="Nombre"
                :type="!anonymus ? 'text' : 'password'"
                :readonly="anonymus"
                :append-icon="detail.shipping.type === 'ANONIMO' ? (!anonymus ? 'visibility' : 'visibility_off') : ''"
                hide-details
                @click:append="anonymus = !anonymus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="detail.user.email"
                label="Email"
                hide-details
                :type="!anonymus ? 'text' : 'password'"
                :readonly="anonymus"
                :append-icon="detail.shipping.type === 'ANONIMO' ? (!anonymus ? 'visibility' : 'visibility_off') : ''"
                @click:append="anonymus = !anonymus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="detail.user.telephone"
                label="Telefono Fijo"
                placeholder="569"
                :type="!anonymus ? 'text' : 'password'"
                :readonly="anonymus"
                :append-icon="detail.shipping.type === 'ANONIMO' ? (!anonymus ? 'visibility' : 'visibility_off') : ''"
                hide-details
                @click:append="anonymus = !anonymus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="detail.user.phone"
                label="Telefono Movil"
                placeholder="569"
                :type="!anonymus ? 'text' : 'password'"
                :readonly="anonymus"
                :append-icon="detail.shipping.type === 'ANONIMO' ? (!anonymus ? 'visibility' : 'visibility_off') : ''"
                hide-details
                @click:append="anonymus = !anonymus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-checkbox v-model="detail.user.problem">
                <template slot="label">
                  <v-tooltip class="ml-1" top>
                    <span slot="activator">
                      Pedido con inconvenientes
                      <v-icon small>help_outline</v-icon>
                    </span>
                    <span
                      >Al activar esta opción en el pedido no se enviarán links de encuestas al cliente (WhatsApp y
                      Email)</span
                    >
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <CustomerBuy
            add-class="text-xs-center px-2 py-2"
            :last-purchase="detail.user.last_purchase"
            :orders-last-year="detail.user.orders_last_year"
            :id-customer="detail.user.id"
            :type="1"
          />
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="updateOrderClient">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!detail.suscription.id">
        <titleCard title="Facturacion" subtitle="Boleta o factura"></titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <v-radio-group v-model="sii.facturacion" :disabled="sii.documento" row @change="changeDocument">
            <v-radio label="Boleta" :value="1"></v-radio>
            <v-radio label="Factura" :value="2"></v-radio>
          </v-radio-group>
          <v-layout v-if="sii.facturacion === 2" row wrap>
            <v-flex xs12 sm12 md12>
              <v-select
                v-model="sii.id"
                :disabled="!editFactura"
                :items="sii.libreta"
                :item-text="
                  e => {
                    return `${e.rut} - ${e.razon_social}`
                  }
                "
                label="Direcciones de facturacion"
                no-data-text="No hay empresas en la libreta del cliente"
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="sii.datos.rut"
                :disabled="!editFactura"
                label="Rut (*)"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="sii.datos.razon_social"
                :disabled="!editFactura"
                label="Razon Social (*)"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="sii.datos.giro" :disabled="!editFactura" label="Giro" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="sii.datos.calle"
                :disabled="!editFactura"
                label="Calle"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                v-model="sii.datos.numero"
                :disabled="!editFactura"
                label="Numero Calle"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                v-model="sii.datos.depto"
                :disabled="!editFactura"
                label="Depto"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                v-model="sii.datos.comuna"
                :disabled="!editFactura"
                label="Comuna"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field v-model="sii.datos.ciudad" :disabled="!editFactura" label="Ciudad"></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-xs-right">
              <v-btn v-if="!editFactura" class="ma-0" color flat small @click="editFactura = true">Editar</v-btn>
              <v-btn v-if="editFactura" class="mr-2 my-0" color="secondary" flat small @click="newSiiDatosFacturacion"
                >Nueva dirección</v-btn
              >
              <v-btn v-if="editFactura" class="ma-0" color="primary" flat small @click="updateSiiDatosFacturacion"
                >Actualizar</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions v-if="sii.documento" class="text-xs-right">
          <v-btn color="success" flat @click="downloadDocument(sii.url_doc)">Documento</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click="nullifyDocument">Anular</v-btn>
          <v-btn color="accent" flat @click="sendDocumentEmail">Enviar</v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="detail.histories[0].status.id !== 0" class="text-xs-right">
          <v-btn color="success" flat @click="dialog.uploadSiiDoc = true">Subir Documento</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="generateSii()">Generar</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="detail.suscription.id">
        <titleCard title="Facturacion" :subtitle="detail.suscription.documento"></titleCard>
        <v-divider light></v-divider>
        <v-card-text v-if="detail.suscription.sii.date">
          Documento electronico generado el día
          {{ detail.suscription.sii.date | moment('DD-MMMM-YYYY') }}
          {{ detail.suscription.sii.date | moment('H:mm:ss') }} hrs
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions v-if="detail.suscription.sii.doc" class="text-xs-right">
          <v-btn color="success" flat @click="downloadDocument(detail.suscription.sii.url)">Documento</v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="sii.documento" class="text-xs-right">
          <v-btn color="success" flat @click="downloadDocument(sii.url_doc)">Documento Individual</v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="detail.histories[0].status.id !== 0" class="text-xs-right">
          <v-btn color="success" flat @click="dialog.uploadSiiDoc = true">Subir Documento</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="generateSiiSuscription(0)">Generar Documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 lg6 md6>
      <v-card class="mb-3">
        <titleCard title="Entrega" subtitle="Persona que recibira el pedido"></titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <v-container class="pa-0" grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="detail.shipping.name"
                  label="Nombre destinatario(a)"
                  placeholder="John Doe"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="detail.shipping.phone"
                  label="Teléfono destinatario(a)"
                  placeholder="569"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="detail.shipping.address"
                  label="Dirección"
                  placeholder="Calle x"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <v-text-field
                  v-model="detail.shipping.address_number"
                  label="Número"
                  placeholder="Nº"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="detail.shipping.commune_id"
                  :items="communes"
                  item-text="name"
                  item-value="id"
                  label="Comunas"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md12>
                <v-textarea
                  v-model="detail.shipping.more"
                  label="Mas información"
                  placeholder="Depto,Empresa,Hospital"
                ></v-textarea>
              </v-flex>

              <v-flex xs4 py-0>
                <v-switch
                  v-model="detail.shipping.photo_hidden"
                  hide-details
                  class="pa-0 ma-0 mb-2"
                  color="secondary"
                  label="No foto"
                  :value="1"
                ></v-switch>
              </v-flex>
              <v-flex xs4 py-0>
                <v-switch
                  v-model="detail.shipping.hidden"
                  hide-details
                  class="pa-0 ma-0 mb-2"
                  color="secondary"
                  label="Oculto"
                  :value="1"
                ></v-switch>
              </v-flex>
              <v-flex xs4 py-0>
                <v-switch
                  v-model="detail.shipping.type"
                  hide-details
                  class="pa-0 ma-0 mb-2"
                  color="secondary"
                  label="Anonimo"
                  value="ANONIMO"
                ></v-switch>
              </v-flex>
              <template v-if="user.id === 6 || user.id === 10 || user.id === 12">
                <v-flex xs6>
                  <v-select
                    v-model="detail.shipping.shopID"
                    :items="shops"
                    item-text="name"
                    item-value="id"
                    label="Zona"
                  ></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-select
                    v-model="detail.shipping.storeID"
                    :items="stores"
                    item-text="name"
                    item-value="id"
                    label="Tienda"
                  ></v-select>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions class="text-xs-right">
          <v-spacer></v-spacer>
          <v-btn :loading="loading" :disabled="loading" color="accent" flat @click="updateOrderShipping">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!detail.suscription.id">
        <titleCard title="Formas de pago" subtitle="Opciones de pago disponibles"></titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm6 md12>
              <v-select
                v-model="payment"
                item-value="id"
                item-text="name"
                :items="methodsPaymet"
                label="Listado de opciones"
              ></v-select>
              <div v-html="paymentHtml"></div>
              <div v-if="detail.payment.id === 18">
                <v-alert
                  v-if="
                    fpayResponse.state === 'reversed' ||
                      fpayResponse.state === 'created' ||
                      fpayResponse.state === 'rejected'
                  "
                  :value="true"
                  type="info"
                >
                  <p>
                    La transaccion no fue pagada
                  </p>
                </v-alert>
                <v-alert v-if="fpayResponse.state === 'paid'" :value="true" type="success">
                  <p>
                    La transaccion fue pagada correctamente
                  </p>
                </v-alert>
                <v-alert v-if="fpayResponse.state === 'refunded'" :value="true" type="error">
                  <p>
                    La transaccion fue pagada correctamente, pero tiene una devolucion total
                  </p>
                </v-alert>
                <v-alert v-if="fpayResponse.state === 'partially_refunded'" :value="true" type="warning">
                  <p>
                    La transaccion fue pagada correctamente, pero tiene una devolucion parcial
                  </p>
                </v-alert>
                <template v-if="fpayResponse.refunds_">
                  <v-data-table
                    v-if="fpayResponse.refunds_.length > 0"
                    :headers="[
                      { text: 'Fecha', align: 'left', sortable: false },
                      { text: 'Estado', align: 'left', sortable: false },
                      { text: 'Monto', align: 'left', sortable: false }
                    ]"
                    :items="fpayResponse.refunds_"
                    class="elevation-0 order-table"
                    color="secondary"
                    :disable-initial-sort="true"
                    hide-actions
                  >
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td class="text-xs-left">{{ props.item.created_at }}</td>
                        <td class="text-xs-left">{{ props.item.status }}</td>
                        <td class="text-xs-left">{{ props.item.amount | toPrice }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
                <code class="d-none">{{ fpayResponse }}</code>
              </div>
              <div v-if="$moment(order.histories[0].date).add(45, 'minutes') > $moment() && payment === 10">
                <v-alert :value="true" color="warning">
                  <strong>
                    El link expira a las
                    {{
                      $moment(order.histories[0].date)
                        .add(45, 'minutes')
                        .format('H:mm:ss')
                    }}
                  </strong>
                </v-alert>
              </div>
              <div v-else-if="$moment(order.histories[0].date).add(45, 'minutes') < $moment() && payment === 10">
                <v-btn :loading="loading" :disabled="loading" color="error" @click="updateLinkPayment">
                  Renovar link
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions class="text-xs-right">
          <v-btn v-if="detail.payment.id !== 18" color="success" flat @click="copy(detail.links.payment)">
            Link de pago
          </v-btn>
          <template v-if="detail.payment.id === 18">
            <!-- v-if="detail.payment.info.refund" -->
            <v-btn
              v-if="
                (fpayResponse.state === 'paid' || fpayResponse.state === 'partially_refunded') &&
                  (user.id === 6 || user.id === 10 || user.id === 12)
              "
              color="error"
              flat
              @click="dialog.refund = true"
            >
              Devolucion
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="updatePayment">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="detail.suscription.id">
        <titleCard title="Suscripcion" subtitle="Este pedido corresponde a una suscripcion"></titleCard>
        <v-divider light></v-divider>
        <v-card-text>
          <p>
            Plan: {{ detail.suscription.plan.name }} <br />
            Total: {{ detail.suscription.total | toPrice }} <br />
            Forma de pago: {{ detail.suscription.payment.name }}<br />
            Otros Pedidos:
            <span v-for="(otros, e) in detail.suscription.others" :key="e">
              <router-link :key="e" target="_blank" :to="{ name: 'orderIndex', params: { id: otros.id_order } }">
                {{ otros.id_order }}
              </router-link>
              {{ e + 1 !== detail.suscription.others.length ? '-' : '' }}
            </span>
          </p>
        </v-card-text>
        <v-divider light></v-divider>
      </v-card>
    </v-flex>
    <v-flex>
      <v-dialog v-model="dialog.uploadSiiDoc" persistent max-width="300px">
        <v-card>
          <titleDialog :title="'Subir Archivo'" :subtitle="' (.jpg, .jpeg, .png)'"></titleDialog>
          <v-divider></v-divider>
          <v-card-text>
            <FileUpload v-model="fileSiiDoc"></FileUpload>
            <v-checkbox v-model="fileSiiNotify" label="Notificar" :value="true"></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn small color="grey darken-1" flat @click.native="dialog.uploadSiiDoc = false">Volver</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="accent" flat @click.native="uploadFileSii">Enviar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.siiError.status" persistent max-width="300px">
        <v-card>
          <titleDialog :title="'Ocurrio un error'"></titleDialog>
          <v-divider></v-divider>
          <v-card-text>
            {{ dialog.siiError.message }}
            <v-text-field v-model="decimales" label="Numero de decimales"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn small color="grey darken-1" flat @click.native="dialog.siiError.status = false">Volver</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="accent" flat @click.native="generateSii">Generar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.refund" persistent max-width="300px">
        <v-card>
          <titleDialog :title="'Devolucion del pedido'"></titleDialog>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="refundAmount"
              type="number"
              min="0"
              :max="refundAmount"
              label="Monto de la devolucion"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn small color="grey darken-1" flat @click.native="dialog.refund = false">Volver</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="accent" flat @click.native="refundFpay">Devolver</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_API, GENERATE_SII, ORDER, GET_STORES, GET_SHOPS, GENERATE_SII_SUSCRIPTION } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'
import FileUpload from '../useful/FileUpload'
import CustomerBuy from '../general/customerBuy.vue'

export default {
  name: 'OrderDetail',
  components: { titleCard, FileReader, titleDialog, FileUpload, CustomerBuy },
  props: ['order', 'getOrder'],
  data() {
    return {
      anonymus: false,
      loading: false,
      editFactura: false,
      decimales: 0,
      fileSiiNotify: false,
      dialog: {
        SMS: false,
        user: false,
        sendDocEmail: false,
        uploadSiiDoc: false,
        siiError: {
          status: false,
          message: null
        },
        refund: false
      },
      mask: {
        phone: '(###) ## ## ####'
      },
      detail: null,
      sii: {
        id: null,
        documento: null,
        facturacion: null,
        libreta: null,
        url: null,
        url_doc: null,
        datos: {
          id: 0,
          rut: null,
          razon_social: null,
          giro: null,
          calle: null,
          numero: null,
          depto: null,
          comuna: null,
          ciudad: null
        }
      },
      fileSii: '',
      payment: 1,
      paymentHtml: '',
      shops: [],
      stores: [],
      fileSiiDoc: null,
      refundAmount: 0,
      fpayResponse: null
    }
  },
  computed: {
    methodsPaymet() {
      return this.$store.getters.getMethodsPaymet
    },
    communes() {
      return this.$store.getters.getCommunes
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    order() {
      this.initOrder(this.order)
    },
    'sii.id': function(e) {
      this.sii.datos = e
    },
    'detail.shipping.shopID': function(e) {
      const r = this._.filter(this.shops, ['id', e])
      this.stores = r[0].stores
    }
  },
  mounted() {
    this.initOrder(this.order)
    this.getShops()
  },
  methods: {
    async getStores() {
      try {
        const res = await this.$http.get(GET_STORES)
        if (res.data) {
          this.stores = res.data
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getShops() {
      try {
        const res = await this.$http.get(GET_SHOPS)
        if (res.data) {
          this.shops = res.data
          const r = this._.filter(this.shops, ['id', this.detail.shipping.shopID])
          this.stores = r[0].stores
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async generateSii() {
      this.dialog.siiError.status = false
      try {
        const res = await this.$http.get(`${GENERATE_SII}${this.order.cod_unico}?decimales=${this.decimales}`)
        if (res.data.error === 1) {
          this.dialog.siiError.status = true
          this.dialog.siiError.message = res.data.msj
        } else {
          this.dialog.siiError.status = false
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Documento generado'
          })
          this.getOrder()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async generateSiiSuscription() {
      this.dialog.siiError.status = false
      try {
        const res = await this.$http.get(
          `${GENERATE_SII_SUSCRIPTION}${this.detail.suscription.code}?decimales=${this.decimales}`
        )
        if (res.data.error === 1) {
          this.dialog.siiError.status = true
          this.dialog.siiError.message = res.data.msj
        } else {
          this.dialog.siiError.status = false
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Documento generado'
          })
          this.getOrder()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateOrderClient() {
      this.loading = true
      try {
        const res = await this.$http.post(`${ORDER}/${this.order.id}/client`, {
          name: this.detail.user.name,
          email: this.detail.user.email,
          telephone: this.detail.user.telephone,
          phone: this.detail.user.phone,
          problem: this.detail.user.problem
        })
        this.loading = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateOrderShipping() {
      this.loading = true
      try {
        await this.$http.post(`${ORDER}/${this.order.id}/shipping`, {
          params: this.detail.shipping
        })
        this.loading = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updatePaymentAction() {
      try {
        const res = await this.$http.post(`${ORDER}/${this.order.id}/payment-change`, {
          id: this.order.id,
          payment: this.payment
        })
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async nullifyDocumentAction() {
      try {
        await this.$http.delete(`${ORDER}/${this.order.id}/sii`)
        this.getOrder()
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateLinkPayment() {
      try {
        await this.$http.put(`${ORDER}/${this.order.id}/update-link-payment`)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateSiiDatosFacturacion() {
      try {
        await this.$http.put(`${ORDER}/${this.order.id}/sii-datos-facturacion`, {
          facturacion: this.sii.facturacion,
          datos: this.sii.datos
        })
        this.getOrder()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado correctamente'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async refundFpay() {
      try {
        const res = await this.$http.put(`${ORDER}/${this.order.id}/refund-fpay`, {
          amount: this.refundAmount
        })
        const data = res.data
        if (data.statusCode) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: data.error_description
          })
        } else if (data.state) {
          if (data.state === 'paid') {
            this.$store.dispatch('changeSnack', {
              active: true,
              text: `Se hizo una devolucion por $${this.$options.filters.toPrice(this.refundAmount)}`
            })
          } else if (data.state === 'partially_refunded') {
            this.$store.dispatch('changeSnack', {
              active: true,
              text: `Se hizo una devolucion parcial por ${this.$options.filters.toPrice(this.refundAmount)}`
            })
          } else if (data.state === 'refunded') {
            this.$store.dispatch('changeSnack', {
              active: true,
              text: `Se hizo una devolucion total por ${this.$options.filters.toPrice(this.refundAmount)}`
            })
          }
          this.dialog.refund = false
          this.getOrder()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: error
        })
      }
    },
    changeDocument() {
      this.updateSiiDatosFacturacion()
    },
    copy(link) {
      this.$copyText(link).then(
        () => {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Copiado con exito!'
          })
        },
        () => {
          alert('No se pudo copiar')
        }
      )
    },
    initOrder(order) {
      this.detail = order
      const datos = this._.filter(order.sii.libreta, ['selected', true])
      this.sii.documento = order.sii.documento
      this.sii.facturacion = order.sii.facturacion
      this.sii.libreta = order.sii.libreta
      this.sii.url = order.sii.url
      this.sii.url_doc = order.sii.url_doc
      if (datos.length > 0) {
        this.sii.datos = datos[0]
        this.sii.id = datos[0]
      }
      this.anonymus = false
      if (this.detail.shipping.type === 'ANONIMO') {
        this.anonymus = true
      }
      this.payment = order.payment.id
      this.paymentHtml = order.payment.htmlPago
      this.$store.dispatch('updateTitle', `Detalle - Pedido ${order.id}`)
      this.refundAmount = order.total
      if (this.detail.payment.id === 18) {
        this.checkStatusFpay(this.detail.id)
      }
    },
    nullifyDocument() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Anular documento?',
        description: 'Al ser anulado, queda registrado en el historial de pedidos.',
        action: this.nullifyDocumentAction
      })
    },
    downloadDocument(url) {
      window.open(url, '_blank')
    },
    handleFileUpload(ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsText(file)
      // this.fileSii = this.$refs.fileSii.files[0];
      // console.log(this.fileSii);
    },
    getFile(file) {
      this.fileSiiDoc = file
    },
    async uploadFileSii() {
      const { facturacion } = this.sii
      if (facturacion === '' || facturacion === null || facturacion === undefined || facturacion === 0) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe seleccionar boleta o factura'
        })
        return false
      }
      this.loading = true
      const formData = new FormData()
      formData.append('p', 'uploadSiiDoc')
      formData.append('file', this.fileSiiDoc)
      formData.append('id', this.order.id)
      formData.append('notify', this.fileSiiNotify)
      formData.append('doc', this.sii.facturacion)

      try {
        await this.$http.post(`${ORDER}/${this.order.id}/sii-doc-upload`, formData)
        this.loading = false
        this.getOrder()
        this.dialog.uploadSiiDoc = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Subido con exito...'
        })
      } catch (e) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    sendDocumentEmail() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Enviar documento por email?',
        description: '',
        action: this.sendDocumentEmailAction
      })
    },
    sendDocumentEmailAction() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'sendDocumentEmail',
          id: this.order.id
        }
      }).then(res => {
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.json.msj
        })
      })
    },
    updatePayment() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Cambiar forma de pago?',
        description: '',
        action: this.updatePaymentAction
      })
    },
    newSiiDatosFacturacion() {
      this.sii.id = {}
      this.sii.datos = {
        id: 0,
        rut: null,
        razon_social: null,
        giro: null,
        calle: null,
        numero: null,
        depto: null,
        comuna: null,
        ciudad: null
      }
    },
    async checkStatusFpay(id) {
      try {
        const res = await this.$http.get(`${ORDER}/${id}/status-fpay`)
        this.fpayResponse = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: error
        })
      }
    }
  }
}
</script>
